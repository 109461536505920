<template>
  <div>
    <section
      id="container"
      style="height: 750px"
    />
  </div>
</template>

<script>
import * as pbi from 'powerbi-client'
import { onMounted } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'
import axios from 'axios'

export default {
  components: {
  },
  data() {
    return {
      embedUrl: null,
      accessToken: null,
      sampleReportUrl: 'https://playgroundbe-bck-1.azurewebsites.net/Reports/SampleReport',
      ReportUrl: `${useAppConfig().AxiosUrl}api_mes/getApiPbi?rpt_pbi_id=d0d8ddda-46a7-4518-90d3-f27c42adb2e8&rpt_pbi_workspace=88d68f5c-bfbc-4ec8-a2ce-451c979e6e32`,
    }
  },
  setup() {
    onMounted(async () => {
      const params = new URLSearchParams()
      params.append('emp_id', localStorage.getItem('id'))
      params.append('nik', localStorage.getItem('nik'))
      params.append('report', 'Device List')
      await axios.post(`${useAppConfig().AxiosUrl}api_mes/user-mes/logreport`, params)
    })
  },
  mounted() {
    this.initializePowerBI().then(() => {
      const permissions = pbi.models.Permissions.All

      const config = {
        type: 'report',
        tokenType: pbi.models.TokenType.Embed,
        accessToken: this.accessToken,
        embedUrl: this.embedUrl,
        pageView: 'fitToWidth',
        permissions,
      }

      // console.log(config)

      const powerbi = new pbi.service.Service(
        pbi.factories.hpmFactory,
        pbi.factories.wpmpFactory,
        pbi.factories.routerFactory,
      )

      const dashboardContainer = document.getElementById('container')
      const dashboard = powerbi.embed(dashboardContainer, config)

      dashboard.off('loaded')
      dashboard.off('rendered')
      // eslint-disable-next-line func-names
      dashboard.on('error', function () {
        this.dashboard.off('error')
      })
    })
  },
  methods: {
    async initializePowerBI() {
      const { ReportUrl } = this

      const reportConfigMES = await fetch(ReportUrl)

      if (!reportConfigMES.ok) {
        console.error('Failed to fetch config for report.')
        console.error('Status:', reportConfigMES.status, reportConfigMES.statusText)
        return
      }

      const reportConfig = await reportConfigMES.json()

      console.log('The access token is set. Loading the Power BI report')

      this.embedUrl = reportConfig.data.embedUrl[0].embedUrl
      this.accessToken = reportConfig.data.accessToken
    },
  },
}
</script>
